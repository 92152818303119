<template>
  <div>
    <div class="imgplayer2" style="background: url(img/gonggao.jpg) no-repeat center;"></div>
    <div class="c_big">
      <div class="c_left">
        <div class="l_top">水司公告</div>
        <ul>
          <a href="javascript:void(0)">
            <li :class="{l_hot:current===0}" @click="current=0,currentPage=1">通知公告</li>
          </a>
          <a href="javascript:void(0)">
            <li :class="{l_hot:current===1}" @click="current=1,currentPage=1">工程公示</li>
          </a>

          <a href="javascript:void(0)">
            <li :class="{l_hot:current===2}" @click="current=2,currentPage=1">水质公告</li>
          </a>

          <a href="javascript:void(0)">
            <li :class="{l_hot:current===3}" @click="current=3,currentPage=1">停水公告</li>
          </a>
        </ul>
      </div>


      <div class="c_right" v-show="current===0">

        <div class="r_top"><span id="r_bt">通知公告</span><span id="map">当前位置：水司公告 &gt; 通知公告</span></div>
        <div v-if="waterAnnouncement1.length||announcement.length">
          <div class="r_txt">
            <ul style="height:300px;overflow-y:auto;">
              <li v-for="(item) in announcement" :key="item.id" @click="showContent(item)">
                <span id="news_nav">•&nbsp; {{ item.title }}</span>
                <span id="time">{{ item.createTime}}</span>
              </li>
              <li v-for="(item,index) in waterAnnouncement1" :key="index">
                <span id="news_nav" @click="openUrl(item)">•&nbsp; {{ item.title }}</span>
                <span id="time">{{ item.createtime |Date}}</span>
              </li>
            </ul>
          </div>

        <!--          分页功能-->
        <div class="flex" style="margin-bottom: 10px;display: flex;">
          <el-button size="mini" @click="gotoFirst()">首页</el-button>
          <el-pagination
              background
              :page-size="15"
              :current-page="currentPage"
              layout="prev, pager, next"
              :total="waterAnnouncement.length"
              @current-change="change"
              prev-text="上一页"
              next-text="下一页"
          >
          </el-pagination>
          <el-button size="mini" @click="gotoEnd(waterAnnouncement)">末页</el-button>
        </div>
        </div>
         <el-empty v-else></el-empty>
      </div>

      <div class="c_right" v-show="current===1">
        <div class="r_top"><span id="r_bt">工程公示</span><span id="map">当前位置：水司公告 &gt; 工程公示</span></div>
          <h1 style="text-align:center;">交通东路自来水管道改造施工通知</h1>
          <div  style="line-height:36px;text-indent:2rem" >
            <p>交通东路沿线自来水用户：</p>
            <p>为确保交通东路沿线用户正常生活用水，我公司决定对交通东路公路局至鑫悦花园段DN400主水管管道全部进行更新改造，确保沿线用户正常生活用水。</p>
            <p>更新改造工程从2022年3月25日开始，至5月25日结束。施工期间，为确保正常施工、保证施工安全，将分段对部分道路进行封闭、半封闭施工，请广大市民进入施工路段，注意安全。用户可以关注“衡东县自来水有限公司”公众号，因工程施工带来的不便，敬请各位居民、商户谅解和支持。</p>
            <p>谢谢合作，特此通知！</p>
            <p>联系电话：
               <span >胡15200565229</span>  
               <span style="display:inline-block;margin-left:5px;">罗13575123046</span> 
               <span style="display:inline-block;margin-left:5px;">颜13973435114</span> 
               <span style="display:inline-block;margin-left:5px;">向13407344732</span> 
            </p>
            <p style="text-align:right">
              衡东县自来水有限公司
            </p>
            <p style="text-align:right">
              2022年3月22日
            </p>
          </div>
      </div>

      <div class="c_right" v-show="current===2">
        <div class="r_top"><span id="r_bt">水质公告</span><span id="map">当前位置：水司公告 &gt; 水质公告</span></div>
        <div v-if="waterNews.length">
        <div class="r_txt">
          <ul style="height:300px;overflow-y:auto;">
            <li style="display:flex" v-for="(item,index) in waterNews" :key="index">
              <router-link style="flex:1" :to="{ path: '/detail', query: { nc: 101034002, id: item.createTime } }">
                <span @click="openUrl(item)">•&nbsp; {{item.date }}水质信息</span>
              </router-link>
              <span>{{ item.createTime}}</span>
            </li>
          </ul>
          
        </div>

        <!--          分页功能-->
        <!-- <div class="flex" style="margin-bottom: 10px;display: flex;">
          <el-button size="mini" @click="gotoFirst()">首页</el-button>
          <el-pagination
              background
              :page-size="15"
              :current-page="currentPage"
              layout="prev, pager, next"
              :total="waterNews.length"
              @current-change="change"
              prev-text="上一页"
              next-text="下一页"
          >
          </el-pagination>
          <el-button size="mini" @click="gotoEnd(waterNews)">末页</el-button>
        </div> -->
        </div>
        <el-empty v-else></el-empty>
        
      </div>

      <div class="c_right" v-show="current===3">
        <div class="r_top"><span id="r_bt">停水公告</span><span id="map">当前位置：水司公告 &gt; 停水公告</span></div>
        <div ></div>
        <div class="r_txt">
          <div id="t_hot" v-if="cutWaterlist.length">
            <dl>
              <dt>{{ cutWaterlist[0].title }}</dt>
              <p style="line-height:25.0000pt;">
                【停水时间】：{{ cutWaterlist[0].tstime }} 至
                {{ cutWaterlist[0].hftime }}
              </p>
              <p style="margin-left:0.0000pt;text-indent:0.0000pt;">【停水主管径】：{{ cutWaterlist[0].tspipe }}</p>
              <p>【停水原因】：{{ cutWaterlist[0].tscause }}</p>
              <p>【停水地点】：{{ cutWaterlist[0].tsscope }} </p>
              <p>【停水客户】：{{ cutWaterlist[0].tsclient }}</p>
              <p>【服务热线】：{{ cutWaterlist[0].tel }}</p>
            </dl>
          </div>
          <ul v-if="cutWaterlist1.length" style="height:400px;overflow-y:auto;">
            <li v-for="(item,index) in cutWaterlist1" :key="index">
              <router-link :to="{ path: '/detail', query: { nc: 101034004, id: item.id } }" target="_blank">
                <span id="news_nav">•&nbsp; {{ item.title }}</span>
              </router-link>
              <span id="time">{{ item.createtime | Date }}</span>
            </li>
          </ul>
          <el-empty v-else></el-empty>
        </div>
        <!--          分页功能-->
        <div class="flex" style="margin-bottom: 10px;display: flex;" v-if="cutWaterlist1.length">
          <el-button size="mini" @click="gotoFirst()">首页</el-button>
          <el-pagination
              background
              :page-size="15"
              :current-page="currentPage"
              layout="prev, pager, next"
              :total="cutWaterlist.length"
              @current-change="change"
              prev-text="上一页"
              next-text="下一页"
          >
          </el-pagination>
          <el-button size="mini" @click="gotoEnd(cutWaterlist)">末页</el-button>
        </div>
      </div>
      <div style="clear: both;"></div>
    </div>
  </div>
</template>

<script>
//引入element组件库
import ElementUI from "element-ui";
// 引入http
import http from "../http/index";
import Vue from "vue";
Vue.use(ElementUI);
export default {
  data() {
    return {
      current: 0,
      currentPage:1,
      waterAnnouncement:[],
      waterExcellent:[],
      waterNews:[],
      cutWaterlist:[],
      announcement:[],
    }
  },
  filters: {
    // 返回一个日期  年-月-日
    Date(val) {
      return val.slice(0, 10);
    },
  },
  methods: {
    showContent(obj){

      if(obj.type==='text'){
          Vue.axios.get(obj.url).then(res=>{
            this.content=res
          })
      }
      if(obj.type==='url'){
        window.open(obj.url)
      }
      this.selected=obj.id;
    },
    async getAnnouncement(){
      this.announcement = await http.getgetAnnouncement()
    },
    async getNotice() {
      try {
        const res = await http.getNotice();
        // console.log("通知公告", res);
        this.waterAnnouncement = res.sort(this.SortDate("createtime", false))
        // this.SwNews = res.rows.sort(this.SortDate('update_time',false));
      } catch (ex) {
        console.log(ex);
}
    },

    async getExcellent() {
      try {
        const res = await http.getExcellent();
        // console.log("gongcheng", res);
        this.waterExcellent = res.sort(this.SortDate("createtime", false))
        // console.log('waterExcellent', this.waterExcellent)
        // this.SwNews = res.rows.sort(this.SortDate('update_time',false));
      } catch (ex) {
        console.log(ex);
      }
    },
    // 获取水质公告
    async getWater() {
      try {
        // const res = await http.getWater({shuiwu:'龙华水务'});
        this.waterNews = await http.getWaterQualityReport();
        console.log('数据',this.waterNews)
        // res.forEach(r=>{
        //   console.log('水质公示',r)
        //   this.waterNews.push(Object.keys(r))
          
        // })
        // console.log('downMonth',this.downMonth)
        // console.log('upMonth',this.upMonth)
        // console.log(Object.values(this.WaterNotice))
        // this.waterNews = Object.keys(res)
      } catch (ex) {
        console.log(ex);
      }
    },
    // 获取停水公告数据
    async cutWater() {
      try {
        const res = await http.cutWater();
        // console.log("5555666", res);
        this.cutWaterlist = res.sort(this.SortDate('createtime',false));
        // console.log(this.cutWaterlist, "++++++++");
        // console.log(this.showid);
        // console.log('GGGGG',this.GPS)
      } catch (ex) {
        console.log(ex);
      }
    },


    // 按时间进行排序算法
    SortDate(time, type) {
      //createtime
      return function (a, b) {
        let value1 = a[time]
        let value2 = b[time]
        if (type) {
          //升序排序
          return Date.parse(value1) - Date.parse(value2)
        } else {
          //降序
          return Date.parse(value2) - Date.parse(value1)
        }
      }
    },
    //分页切换
    change(val) {
      // console.log(`第${val}页`);
      this.currentPage = val;
    },
    // 回到首页
    gotoFirst() {
      this.currentPage = 1;
    },
    // 去到末页
    gotoEnd(list) {
      this.currentPage = Math.ceil(list.length / 15);
    },
    openUrl_time(list){
      console.log("水质报告",list)
      let {href} = this.$router.resolve({path:'/detail',query:{nc:101034004,id:list.createTime}})
        window.open(href,"_blank")
    },
    openUrl(list) {
      
      console.log("水质报告1",list)
      if(list.urltype == 2){
        window.open(list.url)
      }
      if(list.urltype == 0){
        this.$alert(list.content, list.title, {
          confirmButtonText: '确定',
          type:'info'
        });
        // alert(list.content)
      }
      if(list.urltype == 4){
        let {href} = this.$router.resolve({path:'/detail',query:{nc:101034004,id:list.createTime}})
        window.open(href,"_blank")
      }
      console.log("水质报告5",list)
    },
  },
  computed:{
    waterAnnouncement1(){
      return this.waterAnnouncement.slice((this.currentPage - 1) * 15, this.currentPage * 15)
    },
    waterExcellent1(){
      return this.waterExcellent.slice((this.currentPage - 1) * 15, this.currentPage * 15)
    },
    // waterNews1(){
    //   return this.waterNews.slice((this.currentPage - 1) * 15, this.currentPage * 15)
    // },
    cutWaterlist1(){
      return this.cutWaterlist.slice((this.currentPage - 1) * 15, this.currentPage * 15)
    }
  },
  mounted() {
    this.getNotice()
    this.getExcellent()
    this.getWater()
    this.cutWater()
    this.getAnnouncement()
  }
}
</script>

<style>
  #r_bt{
    color: black;
  }
</style>